import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { Img } from '@tueri/react'
import Helmet from 'react-helmet'

const meta = {
  title: 'Lazy-loading images',
  image: '274877906965',
  path: '/features/lazy-loading-images/',
  description: 'Get a speed boost by deferring loading images outside the viewport',
  keywords: 'lazy-loading images, deffered loading, viewport detection'
}

const ImageOptimization = ({ data }) => (
    <Layout blog>
          <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: 'Tueri - ' + meta.title },
              { property: 'twitter:title', content: 'Tueri - ' + meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
          </Helmet>

        <Hero
            large
            title={ meta.title }
            subTitle={ meta.description }
        />

        <div style={{ marginBottom: '8rem' }}>
       	  <Img src={ meta.image } alt='Lazy-loaded images' />
        </div>

		<p>
			Lazying loading images is a technique to boost your initial site loading speed. We accomplish this by deffering loading of any images that are off-screen.
		</p>

		<h2>Off-screen image detection</h2>

		<p>
			Tueri builds a buffer area off-screen and "listens" for images as they are scrolled into the buffer. Your images get loaded just prior to being scrolled into view.
		</p>

    </Layout>
)

export default ImageOptimization